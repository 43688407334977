import {
  auth,
  db,
  FieldValue,
} from '@/firebase';

export default class Food {
  static reference = db.collection('foods');

  static add(foodId, data) {
    return this.reference.doc(foodId).set(data);
  }

  static getRecentItems() {
    return db.collectionGroup('foods')
      .where('createdBy', 'in', [1, auth.currentUser.uid])
      .orderBy('updated', 'desc')
      .limit(30)
      .get()
      .then((snapshot) => {
        const items = [];
        snapshot.docs.forEach((doc) => {
          items.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        return items;
      });
  }

  static create(data) {
    return this.reference.add({
      ...data,
      createdBy: auth.currentUser.uid,
      created: FieldValue.serverTimestamp(),
      updated: FieldValue.serverTimestamp(),
    });
  }

  static delete(id) {
    this.deleteAllFavourites(id);
    return this.reference.doc(id).delete();
  }

  static get(foodId) {
    return this.reference.doc(foodId);
  }

  static set(foodId, input) {
    if (!input.createdBy) {
      throw new TypeError('Unable to save food due to missing property createdBy.');
    }
    if (!input.created) {
      throw new TypeError('Unable to save food due to missing property created.');
    }
    const data = {
      ...input,
      updated: FieldValue.serverTimestamp(),
    };
    return this.reference.doc(foodId).set(data, { merge: true });
  }

  static markFavourite(foodId, isFavourite) {
    const userId = auth.currentUser.uid;
    const doc = this.reference.doc(foodId).collection('favourites').doc(userId);
    if (isFavourite) {
      return doc.set({
        foodId,
        userId,
        isFavourite,
      });
    }
    return doc.delete();
  }

  static calculateNutritionValues(item, selectedAmount) {
    const nutritions = {};
    Object.entries(item.nutritions).map(([name, value]) => {
      if (typeof value === 'number') {
        nutritions[name] = Math.round(
          (value / item.baseAmount) * selectedAmount,
        );
      } else {
        nutritions[name] = value;
      }
      return nutritions[name];
    });
    return nutritions;
  }

  static isCustom(food) {
    const userId = auth.currentUser.uid;
    if (food.createdBy !== userId || food.globalId) {
      return false;
    }
    return true;
  }

  static isFavourite(foodId) {
    const userId = auth.currentUser.uid;
    return this.reference.doc(foodId).collection('favourites').doc(userId).get()
      .then((snapshot) => snapshot.data());
  }

  static deleteAllFavourites(foodId) {
    return this.reference.doc(foodId).collection('favourites')
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
  }
}
