/**
 * Various reusable validation functions.
 */

const emailValidator = (value) => {
  // eslint-disable-next-line
  const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regularExpression.test(String(value).toLowerCase());
};

const passwordValidator = (value) => value.length >= 8;

const positiveNumberValidator = (value) => value && Math.sign(value) === 1;

export {
  emailValidator,
  passwordValidator,
  positiveNumberValidator,
};
