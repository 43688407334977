<template>
  <div
    class="field-group"
    :class="dark ? 'field-group--dark' : 'field-group--light'"
  >
    <p v-if="title">
      {{ title }}
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    dark: { type: Boolean, default: false },
    title: { type: String, default: null },
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.field-group {
  .van-cell {
    flex-direction: column;
    padding: config.$spacing-sm 0;

    &:after {
      display: none;
    }
  }

  .van-field__label {
    width: 100%;
  }

  .van-field__control {
    border-bottom: 1px solid #e7e7e7;
  }
}

.field-group--dark {
  .register__fields,
  .van-cell {
    background-color: transparent;
  }

  .van-cell__title,
  .van-field__label,
  .van-cell__value,
  .van-field__control,
  .van-field__control::placeholder,
  .van-checkbox__label {
    color: #fff;
  }

  .van-field__control {
    border-bottom-color: #fff;
  }

  .van-field--error {
    &,
    .van-field__error-message,
    .van-field__label span {
      color: config.$color-error-light;
    }

    .van-field__control {
      border-color: config.$color-error-light;
    }
  }
}
</style>
