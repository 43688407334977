<template>
  <div class="calculator">
    <van-nav-bar
      :title="title"
      :right-text="$t('close')"
      fixed
      placeholder
      @click-right="$router.back()"
    />
    <p
      v-if="!food"
      class="container calculator__description"
    >
      {{ $t('calculator.description') }}
    </p>
    <van-form
      validate-first
      @submit="submitForm"
    >
      <field-list>
        <h3
          v-if="isCustom()"
          class="container calculator__title"
        >
          {{ $t('calculator.information') }}
        </h3>
        <div
          v-if="isCustom()"
          class="container calculator__cell-group"
        >
          <van-cell
            v-if="withCalculator"
            class="calculator__category full"
            clickable
            :title="$t('calculator.category.label')"
            :label="categoryLabel"
            @click="showCategoryPicker = true"
          >
            <div class="calculator__category__arrow">
              <van-icon :name="showCategoryPicker ? 'arrow-down' : 'arrow-up'" />
            </div>
          </van-cell>
          <van-cell :title="$t('calculator.unit.label')">
            <van-tabs
              v-model="unit"
              type="card"
              @click="setUnit"
            >
              <van-tab :title="$t('grams')" />
              <van-tab :title="$t('millilitres')" />
            </van-tabs>
          </van-cell>
          <van-field
            ref="protein"
            v-model="protein"
            name="protein"
            label-class="calculator__field-label"
            type="text"
            format-trigger="onChange"
            :label="$t('calculator.protein.label', [unitLabel])"
            :placeholder="$t('calculator.protein.placeholder')"
            :formatter="formatter"
            :rules="[
              {
                required: withCalculator,
                message: $t('values.validation.required', [$t('protein')])
              },
              {
                validator: numberValidator,
                message: $t('values.validation.positive')
              },
            ]"
            @keydown.delete="diagnosisValueTouched = false"
            @keypress="diagnosisValueTouched = false"
          />
          <van-field
            v-if="withCalculator"
            v-model="diagnosisValue"
            type="number"
            :label="$t('calculator.target.label', [
              diagnosis.getLabel(false, diagnosis.nutritionKey),
              unitLabel
            ])"
            :placeholder="$t('calculator.generic.placeholder', [
              diagnosis.getLabel(false, diagnosis.nutritionKey)
            ])"
            :formatter="noDecimal"
            format-trigger="onChange"
            :rules="[
              {
                required: true,
                message: $t('values.validation.required', [
                  diagnosis.getLabel(false, diagnosis.nutritionKey)
                ])
              },
              {
                validator: numberValidator,
                message: $t('values.validation.positive')
              },
            ]"
            @keydown.delete="diagnosisValueTouched = true"
            @keypress="diagnosisValueTouched = true"
          />
          <template v-if="useOptionalNutritionValues">
            <van-field
              v-for="nutritionKey in diagnosis.optionalNutritionKeys"
              :key="nutritionKey"
              v-model="optionalDiagnosisValues[nutritionKey]"
              type="text"
              :label="$t('calculator.target.label', [
                diagnosis.getLabel(false, nutritionKey),
                unitLabel
              ])"
              :placeholder="$t('calculator.generic.placeholder', [
                diagnosis.getLabel(false, nutritionKey)
              ])"
              :formatter="noDecimal"
              format-trigger="onChange"
              :rules="[
                {
                  required: Boolean(diagnosis.getLabel(false, nutritionKey)),
                  message: $t('values.validation.required', [
                    diagnosis.getLabel(false, nutritionKey)
                  ])
                },
                { validator: numberValidator,
                  message: $t('values.validation.positive')
                },
              ]"
            />
          </template>
          <van-field
            v-model="name"
            type="text"
            :label="$t('calculator.product.label')"
            :placeholder="$t('calculator.product.placeholder')"
            :rules="[
              {
                required: true,
              },
            ]"
          />
          <van-field
            ref="calories"
            v-model="calories"
            name="calories"
            type="number"
            :label="$t('calculator.calories.label', [unitLabel])"
            :placeholder="$t('calculator.calories.placeholder')"
            :formatter="noDecimal"
            format-trigger="onChange"
            :rules="[
              {
                required: Boolean(name),
                message: $t('values.validation.required', [$t('calories')])
              },
              {
                validator: numberValidator,
                message: $t('values.validation.positive')
              },
            ]"
          />
        </div>

        <h3 class="container calculator__title">
          {{ $t('calculator.portion.title') }}
        </h3>
        <div class="container calculator__cell-group">
          <van-cell
            class="calculator__category full"
            :title="$t('calculator.portion.name')"
            clickable
            :label="portionSizeLabel"
            @click="showPortionSizeNames = true"
          >
            <div class="calculator__category__arrow">
              <van-icon :name="showPortionSizeNames ? 'arrow-down' : 'arrow-up'" />
            </div>
          </van-cell>
          <van-field
            v-if="portionSizeName && portionSizeName !== '- Ohne eigene Menge speichern -'"
            ref="portionAmount"
            v-model="portionAmount"
            name="portionAmount"
            type="number"
            :label="$t('calculator.portion.size', [unitLabel])"
            placeholder="100"
            :formatter="noDecimal"
            format-trigger="onChange"
            clearable
            :clear-trigger="always"
            :rules="[
              {
                required: portionSizeName && portionSizeName !== '- Ohne eigene Menge speichern -',
                message: $t('values.validation.required', [$t('quantity')])
              },
              {
                validator: numberValidator,
                message: $t('values.validation.positive')
              },
            ]"
          />
        </div>
      </field-list>
      <div class="container calculator__submit">
        <van-button
          type="primary"
          native-type="submit"
          block
          round
        >
          {{ $t(food ? 'food.edit' : 'food.create') }}
        </van-button>
      </div>
      <van-popup
        v-model="showPortionSizeNames"
        position="bottom"
      >
        <van-picker
          show-toolbar
          :title="$t('calculator.portion.name')"
          :columns="portionSizeNames"
          @confirm="updatePortionSizeName"
          @cancel="showPortionSizeNames = false"
        />
      </van-popup>
      <van-popup
        v-model="showCategoryPicker"
        position="bottom"
      >
        <van-picker
          show-toolbar
          :title="$t('calculator.category.label')"
          :columns="foodCategories"
          @confirm="updateCategory"
          @cancel="showCategoryPicker = false"
        />
      </van-popup>
    </van-form>
    <div class="branding__container">
      <branding />
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant';
import i18n from '@/i18n';
import Food from '@/models/food';
import Diagnosis, { diagnosesWithoutCalculator } from '@/models/diagnosis';
import FieldList from '@/components/FieldList';
import Branding from '@/components/Branding';
import { positiveNumberValidator } from '@/validators';
import { auth, analytics } from '@/firebase';

import { getNutritionUnit } from '@/helper';

export default {
  components: {
    FieldList,
    Branding,
  },
  props: {
    title: {
      type: String,
      default: i18n.t('calculator.title'),
    },
    food: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      diagnosis: null,
      manualValue: null,
      diagnosisValueTouched: false,
      category: null,
      calories: null,
      name: null,
      protein: null,
      portionSizeName: null,
      portionAmount: null,
      showCategoryPicker: false,
      unit: 0,
      showPortionSizeNames: false,
      portionSizeNames: [],
      optionalDiagnosisValues: {},
      useOptionalNutritionValues: false,
      userId: auth.currentUser.uid,
      touchedPicker: false,
      withCalculator: true,
    };
  },
  computed: {
    unitLabel() {
      return (typeof this.unit === 'number' && this.unit === 0) ? 'g' : 'ml';
    },
    diagnosisValue: {
      get() {
        let calculatedValue = 0;

        if (this.category && this.protein) {
          const diagnosisCategories = this.foodCategories;
          const multiplier = diagnosisCategories[0].values;
          const categoryMultiplier = Object.keys(multiplier).length
            ? multiplier.filter((multi) => multi.text === this.category)
            : [];

          if (this.touchedPicker) {
            calculatedValue = Number(this.parseHelper(this.protein)) * this.category.multiplier;
            if (this.diagnosis.optionalNutritionKeys) {
              this.diagnosis.optionalNutritionKeys.forEach((key) => {
                this.$set(this.optionalDiagnosisValues, key, this.getCalculatedAcidValue(key));
              });
            }
          } else {
            calculatedValue = Number(this.parseHelper(this.protein))
              * categoryMultiplier[0]?.multiplier;
          }
        }

        // If diagnosis value field was touched use custom input instead of the calculated value
        // as long as a value was filled.
        if (!this.diagnosisValueTouched || this.manualValue === null) {
          if (calculatedValue) {
            return Math.round(calculatedValue);
          }
          return null;
        }
        return Math.round(this.manualValue);
      },

      set(value) {
        if (this.diagnosisValueTouched) {
          this.manualValue = value;
        } else {
          this.manualValue = null;
        }
      },
    },

    categoryLabel() {
      if (this.category) {
        return this.category?.text || this.category;
      }
      return this.$i18n.t('calculator.category.empty');
    },

    portionSizeLabel() {
      if (this.portionSizeName) {
        return this.portionSizeName;
      }
      return this.$i18n.t('calculator.portion.empty');
    },

    foodCategories() {
      let categories = {};

      if (this.diagnosis.acidCategories) {
        categories = Object.entries(this.diagnosis.acidCategories).map((entry) => ({
          text: this.$i18n.t(`categories.${entry[0]}`),
          key: entry[0],
          multiplier: entry[1],
        }));
      }

      return [
        {
          values: categories,
          defaultIndex: 0,
        },
      ];
    },

  },
  watch: {
    diagnosisValue(newValue, oldValue) {
      // @todo Prevent invocation during initial component creation when editing food.
      if (oldValue !== newValue) {
        analytics.logEvent('calculate_food', {
          item_name: this.name,
          item_category: this.category.key,
        });
      }
    },
  },
  created() {
    this.diagnosis = new Diagnosis(this.$store.getters['user/diagnosisName']);
    this.withCalculator = !diagnosesWithoutCalculator.includes(this.diagnosis.name);
    this.useOptionalNutritionValues = this.$store.getters['user/useOptionalNutritionValues']
      && this.diagnosis.optionalNutritionKeys;

    // Populate the form if a food was given (edit food)
    if (this.food) {
      this.title = i18n.t('food.edit');
      this.category = this.food.category;
      this.unit = this.food.baseUnit === 'g' ? 0 : 1;
      this.protein = this.food.nutritions.protein?.toString().replace(/\./g, ',');
      this.name = this.food.title;
      this.calories = this.food.nutritions.calories;
      this.portionSizeName = this.food.portionSizeName;
      this.portionAmount = this.food.portionAmount;
      this.diagnosisValue = this.food.nutritions[this.diagnosis.nutritionKey];

      if (this.useOptionalNutritionValues) {
        this.diagnosis.optionalNutritionKeys.forEach((name) => {
          this.$set(this.optionalDiagnosisValues, name, this.food.nutritions[name]);
        });
      }
    }

    this.portionSizeNames.push('- Ohne eigene Menge speichern -');
    Object.entries(this.$i18n.t('food.portionSizeNames')).forEach(([, value]) => {
      this.portionSizeNames.push(value);
    });
  },

  methods: {
    numberValidator(value) {
      if (typeof value === 'string' && value.includes(',')) {
        return positiveNumberValidator(this.parseHelper(value));
      }
      return positiveNumberValidator(value);
    },

    formatter(value) {
      const stripedValue = value
        .replace(/[^0-9,]/g, '')
        .replace(/(,.*?),(.*,)?/, '$1');
      return stripedValue;
    },

    noDecimal(value) {
      return value.replace(/[^0-9]/g, '');
    },

    parseValue() {
      if (this.$refs.protein) this.protein = this.parseHelper(this.$refs.protein.value);
      if (this.$refs.calories) this.calories = this.parseHelper(this.$refs.calories.value);
      if (this.$refs.portionAmount) {
        this.portionAmount = this.parseHelper(this.$refs.portionAmount.value);
      }
      return null;
    },

    parseHelper(value) {
      let toParsed = value;

      if (typeof toParsed === 'string' && toParsed.includes(',')) {
        toParsed = toParsed
          .replace(/,/g, '.')
          .replace(/\.(?=.*\.)/g, '');
      }

      return toParsed;
    },

    submitForm() {
      const foodData = {
        title: this.name,
        category: this?.category?.text || this?.food?.category || '',
        baseUnit: this.unitLabel,
        baseAmount: 100,
        nutritions: {
          calories: this.$helper.convertNumber(this.calories),
          [this.diagnosis.nutritionKey]: this.$helper.convertNumber(this.diagnosisValue)
            || ((this.food && this.food.nutritions)
              ? this.food.nutritions[this.diagnosis.nutritionKey] : 0),
          // Set this last for diagnosesWithoutCalculator to save the protein
          // field input instead of the (non-existing) diagnosisValue.
          protein: this.$helper.convertNumber(this.protein),
        },
        portionAmount: this.portionSizeName
          && this.portionSizeName !== '- Ohne eigene Menge speichern -'
          && this.portionAmount
          ? this.$helper.convertNumber(this.portionAmount) : null,
        portionSizeName: this.portionSizeName
          && this.portionSizeName !== '- Ohne eigene Menge speichern -'
          && this.portionAmount
          ? this.portionSizeName : null,
      };

      if (this.useOptionalNutritionValues) {
        Object.entries(this.optionalDiagnosisValues).forEach(([name, value]) => {
          foodData.nutritions[name] = this.$helper.convertNumber(value);
        });
      }

      if (this.food) {
        // adds 'globalId' to a copy of global food
        if (this.food.globalId) {
          foodData.globalId = this.food.globalId;
        } else if (this.food.createdBy !== this.userId) {
          foodData.globalId = this.food.id;
        }
        if (foodData.globalId) {
          foodData.nutritions = { ...this.food.nutritions };
        } else {
          foodData.nutritions = { ...this.food.nutritions, ...foodData.nutritions };
        }
        foodData.createdBy = this.food.createdBy;
        foodData.created = this.food.created;
      }

      // In case a food was provided, update it instead of creating a new one
      if (this.food && this.food.createdBy === this.userId) {
        foodData.id = this.food.id;
        foodData.title = this.name;

        Food.set(foodData.id, foodData).then(() => {
          Notify({
            type: 'primary',
            message: this.$i18n.t('food.created'),
            duration: 5000,
          });
          analytics.logEvent('food_edit', {
            item_id: foodData.id,
            item_name: foodData.title,
            item_category: foodData.category,
          });

          this.$router.push({
            name: 'FoodDetailsView',
            params: {
              id: foodData.id,
            },
          });
        });
        return;
      }

      Food.create(foodData).then((docRef) => {
        Notify({
          type: 'primary',
          message: this.$i18n.t('food.created'),
          duration: 5000,
        });
        analytics.logEvent('food_create', {
          item_id: docRef.id,
          item_name: foodData.title,
          item_category: foodData.category,
        });

        this.$router.push({
          name: 'FoodDetailsView',
          params: {
            mealType: 'breakfast',
            id: docRef.id,
          },
        });
      });
    },

    updateCategory(value) {
      this.touchedPicker = true;
      this.category = value.pop();
      this.showCategoryPicker = false;
      this.diagnosisValueTouched = false;
    },

    updatePortionSizeName(value) {
      this.portionSizeName = value;
      this.showPortionSizeNames = false;
    },

    getNutritionUnit,

    setUnit(value) {
      this.unit = value;
    },

    isCustom() {
      if (this.food) {
        return Food.isCustom(this.food);
      }
      return true;
    },

    getCalculatedAcidValue(optionalKey) {
      const acidValue = this.diagnosis[optionalKey][this.category.key];
      return acidValue * Number(this.protein);
    },
  },
};
</script>

<style lang="scss" scoped>
 @use 'sass:math';
  @use '~@/styles/config' as config;
  .calculator {
    padding-bottom: 16px; // Ensure 'Add' button doesn't touch nav bar.
    min-height: calc(100vh - 70px); // 66px is the current height of nav, +4
    background-color: #FFFFFF;
    &__title {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
  }

  .calculator__description {
    text-align: center;
    margin: config.$spacing-sm auto config.$spacing-lg auto;
  }

  .calculator__config {
    margin-bottom: config.$spacing-sm;
    padding: config.$spacing-sm 0;
  }

  .calculator__config,
  .calculator__config .van-cell {
    font-size: config.$font-size;
    text-align: center;
    background-color: config.$color-lighter;
  }

  .unit-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .calculator__category {
    position: relative;
    width: 65%;
    &.full {
      width: 100%;
    }
    .van-cell__value {
      position: static;
      overflow: visible;
    }

    .van-cell__label {
      padding-bottom: config.$spacing-sm;
      border-bottom: 1px solid config.$color-mid;
    }
  }

  .calculator__category__arrow {
    position: absolute;
    top: config.$spacing-sm;
    right: 0;
    font-size: 24px;
  }

  .calculator__cell-group {
    background-color: config.$color-lighter;
    padding-top: 1rem;
    padding-bottom: 1rem;
    >:first-child {
      border-radius: math.div(config.$spacing-sm, 2) math.div(config.$spacing-sm, 2) 0 0;
    }
    >:last-child {
      border-radius: 0 0 math.div(config.$spacing-sm, 2) math.div(config.$spacing-sm, 2);
      padding-bottom: 20px !important;
    }
    ::v-deep .van-tabs__wrap {
      text-align: left;
      height: 40px;
      margin-top: 0.5rem;
    }
    ::v-deep .van-tabs__nav {
      display: inline-flex;
      margin: 0;
      min-width: 200px;
      height: 32px;
      border: 0;
      background-color: transparent;
    }
    ::v-deep .van-tab {
      color: #323233;
      border: 2px solid config.$brand-secondary;
      font-size: 16px;

      &:first-child {
        border-radius: 14px 0 0 14px;
      }
      &:last-child {
        border-left: 0;
        border-radius: 0 14px 14px 0;
      }
    }
    ::v-deep .van-tab--active {
      background-color: rgba(config.$brand-secondary, 0.2);
    }
  }

  .calculator__submit {
    margin: config.$spacing-xxl auto;
  }

  .van-cell {
    font-size: config.$font-size;
    background-color: transparent;
  }

  ::v-deep .calculator__config .van-col:last-child {
    border-left: 1px solid config.$color-mid-light;
  }

  ::v-deep .van-field__label {
    color: config.$color-darker;
  }

  ::v-deep .van-cell__label {
    font-size: config.$font-size-sm;
  }

  ::v-deep .van-cell__title {
    flex-direction: column;
  }

  ::v-deep .van-field__control {
    background-color: transparent;

    &:focus {
      border-color: config.$brand-primary;
    }
  }
  ::v-deep .van-field__body input {
    padding: 0.5rem 0;
    border-bottom: 1px solid config.$color-mid;
    font-size: 15px;
  }
</style>
<style>
  .van-cell__title {
    flex-direction: column;
  }
</style>
