var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calculator"},[_c('van-nav-bar',{attrs:{"title":_vm.title,"right-text":_vm.$t('close'),"fixed":"","placeholder":""},on:{"click-right":function($event){return _vm.$router.back()}}}),(!_vm.food)?_c('p',{staticClass:"container calculator__description"},[_vm._v(" "+_vm._s(_vm.$t('calculator.description'))+" ")]):_vm._e(),_c('van-form',{attrs:{"validate-first":""},on:{"submit":_vm.submitForm}},[_c('field-list',[(_vm.isCustom())?_c('h3',{staticClass:"container calculator__title"},[_vm._v(" "+_vm._s(_vm.$t('calculator.information'))+" ")]):_vm._e(),(_vm.isCustom())?_c('div',{staticClass:"container calculator__cell-group"},[(_vm.withCalculator)?_c('van-cell',{staticClass:"calculator__category full",attrs:{"clickable":"","title":_vm.$t('calculator.category.label'),"label":_vm.categoryLabel},on:{"click":function($event){_vm.showCategoryPicker = true}}},[_c('div',{staticClass:"calculator__category__arrow"},[_c('van-icon',{attrs:{"name":_vm.showCategoryPicker ? 'arrow-down' : 'arrow-up'}})],1)]):_vm._e(),_c('van-cell',{attrs:{"title":_vm.$t('calculator.unit.label')}},[_c('van-tabs',{attrs:{"type":"card"},on:{"click":_vm.setUnit},model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}},[_c('van-tab',{attrs:{"title":_vm.$t('grams')}}),_c('van-tab',{attrs:{"title":_vm.$t('millilitres')}})],1)],1),_c('van-field',{ref:"protein",attrs:{"name":"protein","label-class":"calculator__field-label","type":"text","format-trigger":"onChange","label":_vm.$t('calculator.protein.label', [_vm.unitLabel]),"placeholder":_vm.$t('calculator.protein.placeholder'),"formatter":_vm.formatter,"rules":[
            {
              required: _vm.withCalculator,
              message: _vm.$t('values.validation.required', [_vm.$t('protein')])
            },
            {
              validator: _vm.numberValidator,
              message: _vm.$t('values.validation.positive')
            },
          ]},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;_vm.diagnosisValueTouched = false},"keypress":function($event){_vm.diagnosisValueTouched = false}},model:{value:(_vm.protein),callback:function ($$v) {_vm.protein=$$v},expression:"protein"}}),(_vm.withCalculator)?_c('van-field',{attrs:{"type":"number","label":_vm.$t('calculator.target.label', [
            _vm.diagnosis.getLabel(false, _vm.diagnosis.nutritionKey),
            _vm.unitLabel
          ]),"placeholder":_vm.$t('calculator.generic.placeholder', [
            _vm.diagnosis.getLabel(false, _vm.diagnosis.nutritionKey)
          ]),"formatter":_vm.noDecimal,"format-trigger":"onChange","rules":[
            {
              required: true,
              message: _vm.$t('values.validation.required', [
                _vm.diagnosis.getLabel(false, _vm.diagnosis.nutritionKey)
              ])
            },
            {
              validator: _vm.numberValidator,
              message: _vm.$t('values.validation.positive')
            },
          ]},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;_vm.diagnosisValueTouched = true},"keypress":function($event){_vm.diagnosisValueTouched = true}},model:{value:(_vm.diagnosisValue),callback:function ($$v) {_vm.diagnosisValue=$$v},expression:"diagnosisValue"}}):_vm._e(),(_vm.useOptionalNutritionValues)?_vm._l((_vm.diagnosis.optionalNutritionKeys),function(nutritionKey){return _c('van-field',{key:nutritionKey,attrs:{"type":"text","label":_vm.$t('calculator.target.label', [
              _vm.diagnosis.getLabel(false, nutritionKey),
              _vm.unitLabel
            ]),"placeholder":_vm.$t('calculator.generic.placeholder', [
              _vm.diagnosis.getLabel(false, nutritionKey)
            ]),"formatter":_vm.noDecimal,"format-trigger":"onChange","rules":[
              {
                required: Boolean(_vm.diagnosis.getLabel(false, nutritionKey)),
                message: _vm.$t('values.validation.required', [
                  _vm.diagnosis.getLabel(false, nutritionKey)
                ])
              },
              { validator: _vm.numberValidator,
                message: _vm.$t('values.validation.positive')
              },
            ]},model:{value:(_vm.optionalDiagnosisValues[nutritionKey]),callback:function ($$v) {_vm.$set(_vm.optionalDiagnosisValues, nutritionKey, $$v)},expression:"optionalDiagnosisValues[nutritionKey]"}})}):_vm._e(),_c('van-field',{attrs:{"type":"text","label":_vm.$t('calculator.product.label'),"placeholder":_vm.$t('calculator.product.placeholder'),"rules":[
            {
              required: true,
            },
          ]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('van-field',{ref:"calories",attrs:{"name":"calories","type":"number","label":_vm.$t('calculator.calories.label', [_vm.unitLabel]),"placeholder":_vm.$t('calculator.calories.placeholder'),"formatter":_vm.noDecimal,"format-trigger":"onChange","rules":[
            {
              required: Boolean(_vm.name),
              message: _vm.$t('values.validation.required', [_vm.$t('calories')])
            },
            {
              validator: _vm.numberValidator,
              message: _vm.$t('values.validation.positive')
            },
          ]},model:{value:(_vm.calories),callback:function ($$v) {_vm.calories=$$v},expression:"calories"}})],2):_vm._e(),_c('h3',{staticClass:"container calculator__title"},[_vm._v(" "+_vm._s(_vm.$t('calculator.portion.title'))+" ")]),_c('div',{staticClass:"container calculator__cell-group"},[_c('van-cell',{staticClass:"calculator__category full",attrs:{"title":_vm.$t('calculator.portion.name'),"clickable":"","label":_vm.portionSizeLabel},on:{"click":function($event){_vm.showPortionSizeNames = true}}},[_c('div',{staticClass:"calculator__category__arrow"},[_c('van-icon',{attrs:{"name":_vm.showPortionSizeNames ? 'arrow-down' : 'arrow-up'}})],1)]),(_vm.portionSizeName && _vm.portionSizeName !== '- Ohne eigene Menge speichern -')?_c('van-field',{ref:"portionAmount",attrs:{"name":"portionAmount","type":"number","label":_vm.$t('calculator.portion.size', [_vm.unitLabel]),"placeholder":"100","formatter":_vm.noDecimal,"format-trigger":"onChange","clearable":"","clear-trigger":_vm.always,"rules":[
            {
              required: _vm.portionSizeName && _vm.portionSizeName !== '- Ohne eigene Menge speichern -',
              message: _vm.$t('values.validation.required', [_vm.$t('quantity')])
            },
            {
              validator: _vm.numberValidator,
              message: _vm.$t('values.validation.positive')
            },
          ]},model:{value:(_vm.portionAmount),callback:function ($$v) {_vm.portionAmount=$$v},expression:"portionAmount"}}):_vm._e()],1)]),_c('div',{staticClass:"container calculator__submit"},[_c('van-button',{attrs:{"type":"primary","native-type":"submit","block":"","round":""}},[_vm._v(" "+_vm._s(_vm.$t(_vm.food ? 'food.edit' : 'food.create'))+" ")])],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showPortionSizeNames),callback:function ($$v) {_vm.showPortionSizeNames=$$v},expression:"showPortionSizeNames"}},[_c('van-picker',{attrs:{"show-toolbar":"","title":_vm.$t('calculator.portion.name'),"columns":_vm.portionSizeNames},on:{"confirm":_vm.updatePortionSizeName,"cancel":function($event){_vm.showPortionSizeNames = false}}})],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showCategoryPicker),callback:function ($$v) {_vm.showCategoryPicker=$$v},expression:"showCategoryPicker"}},[_c('van-picker',{attrs:{"show-toolbar":"","title":_vm.$t('calculator.category.label'),"columns":_vm.foodCategories},on:{"confirm":_vm.updateCategory,"cancel":function($event){_vm.showCategoryPicker = false}}})],1)],1),_c('div',{staticClass:"branding__container"},[_c('branding')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }